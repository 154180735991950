
//.don_side_menu
  //visibility hidden
  //&.go
  //  visibility visible
.don_lazy[data-src]
  display inline-block !important
  position absolute !important
  top 0 !important
  left 0 !important
  width 100% !important
  height 100% !important
.don_lazy
  opacity 0
  transition opacity 300ms ease-in-out
  &.b-loaded
    opacity 1


@-moz-keyframes atebits-loader2 {
  0% {
    -moz-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  3% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  10% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  13% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  20% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  23% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  30% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  33% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  40% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  43% {
    -moz-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  50% {
    -moz-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  53% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  60% {
    -moz-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  63% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  70% {
    -moz-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  73% {
    -moz-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  80% {
    -moz-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  83% {
    -moz-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  90% {
    -moz-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  93% {
    -moz-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
}

@-webkit-keyframes atebits-loader2 {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  3% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  10% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  13% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  20% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  23% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  30% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  33% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  40% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  43% {
    -webkit-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  50% {
    -webkit-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  53% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  60% {
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  63% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  70% {
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  73% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  80% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  83% {
    -webkit-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  90% {
    -webkit-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  93% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
}

@keyframes atebits-loader2 {
  0% {
    -moz-transform: rotate(0deg) scale(0.7);
    -ms-transform: rotate(0deg) scale(0.7);
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  3% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  10% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  13% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  20% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  23% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  30% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  33% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  40% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  43% {
    -moz-transform: rotate(270deg) scale(0.7);
    -ms-transform: rotate(270deg) scale(0.7);
    -webkit-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  50% {
    -moz-transform: rotate(270deg) scale(0.7);
    -ms-transform: rotate(270deg) scale(0.7);
    -webkit-transform: rotate(270deg) scale(0.7);
    transform: rotate(270deg) scale(0.7);
  }
  53% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  60% {
    -moz-transform: rotate(180deg) scale(0.7);
    -ms-transform: rotate(180deg) scale(0.7);
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
  }
  63% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  70% {
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    -webkit-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  73% {
    -moz-transform: rotate(0deg) scale(0.7);
    -ms-transform: rotate(0deg) scale(0.7);
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  80% {
    -moz-transform: rotate(0deg) scale(0.7);
    -ms-transform: rotate(0deg) scale(0.7);
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
  83% {
    -moz-transform: rotate(-90deg) scale(0.7);
    -ms-transform: rotate(-90deg) scale(0.7);
    -webkit-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  90% {
    -moz-transform: rotate(-90deg) scale(0.7);
    -ms-transform: rotate(-90deg) scale(0.7);
    -webkit-transform: rotate(-90deg) scale(0.7);
    transform: rotate(-90deg) scale(0.7);
  }
  93% {
    -moz-transform: rotate(0deg) scale(0.7);
    -ms-transform: rotate(0deg) scale(0.7);
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
  }
}

.atebits-loader2 {
  //background: rgba(0, 0, 0, 0.5);
  -moz-transform: rotate(0deg) scale(0.7);
  -ms-transform: rotate(0deg) scale(0.7);
  -webkit-transform: rotate(0deg) scale(0.7);
  transform: rotate(0deg) scale(0.7);
  display: inline-block;
  width: 9px;
  height: 9px;
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  -moz-box-shadow:
          rgba(0, 0, 0, 0.5) -12px -24px 0 0,
          rgba(0, 0, 0, 0.5) 12px -24px 0 0,

          rgba(0, 0, 0, 0.5) -24px -12px 0 0,
          rgba(0, 0, 0, 0.5) -12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 0 -12px 0 0,
          rgba(0, 0, 0, 0.5) 12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 24px -12px 0 0,

          rgba(0, 0, 0, 0.5) -12px 0 0 0,
          rgba(0, 0, 0, 0.5) 12px 0 0 0,

          rgba(0, 0, 0, 0.5) 24px 12px 0 0,
          rgba(0, 0, 0, 0.5) 12px 12px 0 0,
          rgba(0, 0, 0, 0.5) 0 12px 0 0,
          rgba(0, 0, 0, 0.5) -12px 12px 0 0,
          rgba(0, 0, 0, 0.5) -24px 12px 0 0,

          rgba(0, 0, 0, 0.5) 12px 24px 0 0,
          rgba(0, 0, 0, 0.5) -12px 24px 0 0;

  -webkit-box-shadow:
          rgba(0, 0, 0, 0.5) -12px -24px 0 0,
          rgba(0, 0, 0, 0.5) 12px -24px 0 0,

          rgba(0, 0, 0, 0.5) -24px -12px 0 0,
          rgba(0, 0, 0, 0.5) -12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 0 -12px 0 0,
          rgba(0, 0, 0, 0.5) 12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 24px -12px 0 0,

          rgba(0, 0, 0, 0.5) -12px 0 0 0,
          rgba(0, 0, 0, 0.5) 12px 0 0 0,

          rgba(0, 0, 0, 0.5) 24px 12px 0 0,
          rgba(0, 0, 0, 0.5) 12px 12px 0 0,
          rgba(0, 0, 0, 0.5) 0 12px 0 0,
          rgba(0, 0, 0, 0.5) -12px 12px 0 0,
          rgba(0, 0, 0, 0.5) -24px 12px 0 0,

          rgba(0, 0, 0, 0.5) 12px 24px 0 0,
          rgba(0, 0, 0, 0.5) -12px 24px 0 0;
  box-shadow:
          rgba(0, 0, 0, 0.5) -12px -24px 0 0,
          rgba(0, 0, 0, 0.5) 12px -24px 0 0,

          rgba(0, 0, 0, 0.5) -24px -12px 0 0,
          rgba(0, 0, 0, 0.5) -12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 0 -12px 0 0,
          rgba(0, 0, 0, 0.5) 12px -12px 0 0,
          rgba(0, 0, 0, 0.5) 24px -12px 0 0,

          rgba(0, 0, 0, 0.5) -12px 0 0 0,
          rgba(0, 0, 0, 0.5) 12px 0 0 0,

          rgba(0, 0, 0, 0.5) 24px 12px 0 0,
          rgba(0, 0, 0, 0.5) 12px 12px 0 0,
          rgba(0, 0, 0, 0.5) 0 12px 0 0,
          rgba(0, 0, 0, 0.5) -12px 12px 0 0,
          rgba(0, 0, 0, 0.5) -24px 12px 0 0,

          rgba(0, 0, 0, 0.5) 12px 24px 0 0,
          rgba(0, 0, 0, 0.5) -12px 24px 0 0;

  -moz-animation: atebits-loader2 8s infinite ease-in-out;
  -webkit-animation: atebits-loader2 8s infinite ease-in-out;
  animation: atebits-loader2 8s infinite ease-in-out;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin-top 20px
}

.don_loader_wrap3
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(255,255,255,0.8)
  z-index 2147483647
  .atebits-loader
    @media screen and (max-width 1024px)
      margin-top -10px
    @media screen and (min-width 1025px)
      margin-top -15px
    width 75px
    height 75px
    margin auto
    background url(//s3.marpple.co/files/u_1187078/2021/6/original/d404ad5609f271b95ced51274b714923d7d1d2b11.png) no-repeat
    animation: spin 3s linear infinite;
    background-size 75px 75px
    @media screen and (max-width 1024px)
      background-size 55px 55px
      width 55px
      height 55px
    opacity 0.9
  .content
    margin auto
    width 100%
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    z-index 2147483647
    >.body
      position relative
      text-align center
      .msg
        display inline-block
        margin-bottom 10px
        width 100%
        word-break keep-all
        word-wrap break-word
        font-size 30px
        line-height 1.43
        text-align center
        color #111
        @media screen and (max-width 1024px)
          margin-bottom 2px
          font-size 18px
        &:last-child
          margin-bottom 15px
.don_dialog_msg
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(0,0,0,0.35)
  width 100%
  height 100%
  z-index 2147483647
  &.modal
    .content
      width auto
  .content
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-color #ffffff
    z-index 2147483647
    position relative
    display flex
    flex-direction column
    box-sizing border-box
    border-radius 8px
    max-width 87.2%
    padding 44px 28px 28px
    >.body
      position relative
      margin-bottom 32px
      .msg
        font-size 18px
        line-height 1.56
        letter-spacing -0.2px
        text-align center
        color #111111
        word-break keep-all
        word-wrap break-word
        @media screen and (max-width: 1024px)
          font-size 16px
        a
          text-decoration underline
        p
          font-size 16px
          line-height 1.25
          letter-spacing -0.2px
          color #a2a2a2
          margin 6px 0 0
          text-align left
          @media screen and (max-width: 1024px)
            font-size 14px
    >.footer
      position relative
      .buttons
        button
          border-radius 4px
          padding 0
          width 100%
          height 44px
          background-color #000
          font-size 15px
          color #ffffff
          font-weight 200
          cursor pointer
          line-height 1.27
          letter-spacing -0.2px
          box-sizing border-box
          border none
  &#don_confirm
    .content
      >.footer
        >.buttons
          display flex
          margin 0 -2px
          button
            margin 0 2px
          .ok
            background #0e2ee2
  html[is_mobile="false"] &
    .content
      width 400px
      padding 50px
      >.body
        margin-bottom 40px
        .msg
          font-size 18px
          line-height 1.3
          letter-spacing -0.3px
          p
            font-size 15px
            line-height 1.33
            margin 12px 0 0
      //>.footer
      //  position relative
      //  .buttons
      //    button
      //      height 52px
      //      font-size 18px
      //      line-height 1.33
    &#don_confirm
      .content
        >.footer
          >.buttons
            margin 0 -4px
            button
              margin 0 4px
button#don_up
  position fixed
  border 0
  border-radius 20px
  bottom 80px
  right 20px
  width 40px
  height 40px
  background-color rgba(22,22,22, .7)
  -webkit-transition transform .5s linear
  -moz-transition transform .5s linear
  transition transform .5s linear
  z-index 100
  transform translate3d(100px, 0, 0)
  &.show
    transform translate3d(0, 0, 0)
    -webkit-transition transform .5s linear
    -moz-transition transform .5s linear
    transition transform .5s linear
  &:after
    content ''
    background-image url("//s3.marpple.co/files/u_29089/2019/1/original/f_23334_1548936067679_Rw8AsVFG8uDznMEc4s.png")
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    margin auto
    width 10px
    height 6px
    background-size 100%
    transform rotate(180deg)
  @media screen and (max-width: 1024px)
    right 10px
    bottom 20px
#don_is_loader
  width 100%
  height 80px
  position relative
.don_pgn_container
.don_is_container
  position relative
  min-height 100%
  .don_pagination
    position relative
    text-align center
    .pagination-wrap
      font-size 16px
      >*
        position relative
        display inline-block
        vertical-align top
        height 38px
      .zzz
        height 28px
        vertical-align bottom
        margin 0 10px
      .message
        display block
        font-size 11px
        font-weight 200
        height 13px
        margin-top 3px
      input[type="number"]
        font-size 16px
        padding 0
        margin 0
        line-height 38px
        height 36px
        width 38px
        text-align center
        border 1px solid #d2d2d2
        border-radius 1px
        -webkit-appearance: none;
        &::-webkit-inner-spin-button
        &::-webkit-outer-spin-button
          -webkit-appearance none
          margin 0
      span.slash
        width 25px
        background-repeat no-repeat
        background-position 40% 50%
        background-image url(//s3.marpple.co/s/5/marpple2/img/main2/slash.png)
      span.max
        font-weight 300
        line-height 40px
      button
        background transparent
        border 0
        padding 0
        width 40px
        height 40px
        margin 0 15px
        background-repeat no-repeat
        background-position center
        &.next
          background-image url(//s3.marpple.co/s/5/marpple2/img/main2/next_button.png)
        &.prev
          background-image url(//s3.marpple.co/s/5/marpple2/img/main2/prev_button.png)
  .don_pgn_wrap
    position relative
    .don_pgn_item
      position relative
      display inline-block
      vertical-align top
  .don_is_item
    visibility hidden
    position absolute !important
    left 0
    top 0
    &.visible
      visibility visible !important
  .don_img_wrap
    overflow hidden
    height 0
    padding-bottom 100%
    position relative
    margin auto
    img
      max-height 100%
      max-width 100%
      margin auto
      position absolute
      top 0
      left 0
      right 0
      bottom 0

// animation config

.don_small_loader_wrap,
.don_loader_mp_maker_wrap
.don_loader_wrap
  z-index 2147483647
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  .don_loader_img
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    width 48px
    height 48px
    margin auto
    background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
    background-size 48px 48px
    opacity 0.9

.canvas_100
.canvas_120
.canvas_860
.canvas_60
  position relative

.don_loader_wrap2
  z-index 2147483647
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  transform scale(1) !important
  .don_loader_img2
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    max-width 48px
    max-height 48px
    margin auto
    background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
    background-size 48px 48px
    opacity 0.5

@media only screen and (min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)
  .don_loader_wrap
    .don_loader_img
      background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
      background-size 48px 48px

  .don_loader_wrap2
    .don_loader_img2
      background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
      background-size 48px 48px

.don_select_wrapper
  position relative
  >.selected
    cursor pointer
.don_select_list
  background #fff
  border 1px solid #d5dbe0
  z-index 999999999
  ul
    margin 0
    list-style none
    padding 0 16px 12px
    li
      cursor pointer
      padding 5px 0
      margin 4px 0
      &[selected="true"]
        font-weight bold
  .selected_true
    position relative
    padding 12px 32px 12px 16px
    display inline-block
    &:after
      content ''
      position absolute
      top 16px
      right 8px
      width 10px
      height 6px
      background-size 100%
      background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5873_1537284696901_Hu1LJm8fTExZNo9SVE.png")
@media screen and (min-width 1025px)
  .don_banner
    width 100%
    min-width 1280px
    background-position center
    background-size cover
@media screen and (max-width 1024px)
  .don_banner
    width 100%
    background-position center
    background-size 100% 100%
